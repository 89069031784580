/* eslint-disable no-unused-vars */
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
/*
import { messaging } from "./firebase";
import { getToken } from "firebase/messaging";
import { register } from "register-service-worker";
*/
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyATW1Ybkfqz2Bt3-0nayrfz2pww_o6DGkQ",
  authDomain: "nearbite-534a8.firebaseapp.com",
  projectId: "nearbite-534a8",
  storageBucket: "nearbite-534a8.appspot.com",
  messagingSenderId: "46296819811",
  appId: "1:46296819811:web:b250380cf6449eca75f056",
  measurementId: "G-MSPVWV31GG",
};
const appInstance = initializeApp(firebaseConfig);

const messaging = getMessaging(appInstance);

Notification.requestPermission().then((permission) => {
  if (permission === "granted") {
    console.log("Berechtigung erteilt");

    getToken(messaging, {
      vapidKey:
        "BL7qO8PSiH_rpv5n10t0pdRkY1ToGpjzHALiJzUp7cGjPLDZMn_QvmIWYKn1nIvOWR6gyT-jSL3wr6rf3mxWj_M",
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("Token:", currentToken);
          showFBToken(currentToken);
        } else {
          console.log(
            "Kein Registrierungstoken verfügbar. Fordern Sie die Berechtigung zum Senden von Benachrichtigungen an."
          );
        }
      })
      .catch((err) => {
        console.log("Fehler beim Abrufen des Tokens:", err);
      });
  } else {
    console.log("Unable to get permission to notify.");
  }
});

function showFBToken(token) {
  if (document.querySelector("#device-token")) {
    document.querySelector("#device-token").innerHTML = token;
  } else {
    window.setTimeout(function () {
      showFBToken(token);
    }, 100);
  }
}

onMessage(messaging, (payload) => {
  console.log("Nachricht erhalten:", payload);
});

const app = createApp(App);
app.use(store);
app.use(router);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        console.log(
          "Service Worker für Firebase Messaging registriert:",
          registration
        );
        messaging.useServiceWorker(registration); // Verknüpfen des Service Workers mit Firebase Messaging
      })
      .catch((err) => {
        console.log("Fehler bei der Registrierung des Service Worker:", err);
      });
  });
}

app.mount("#app");
