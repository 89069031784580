<template>
  <div class="company" @click="openRestaurant(company)">
    <div class="image">
      <img :src="imageUrl" />
    </div>

    <div class="content">
      <h3>
        {{
          company.name.length > 30
            ? company.name.substring(0, 24) + "..."
            : company.name
        }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeRestaurantsItem",
  data() {
    return {
      liked: false,
      imageUrl: "",
    };
  },
  props: {
    company: Object,
  },
  created() {
    this.generateRandomImageUrl();
    this.checkIfLiked();
  },
  methods: {
    companyCategory(category) {
      if (category == "lodging") {
        return "Unterkunft";
      } else if (category == "restaurant") {
        return "Restaurant";
      } else if (category == "meal_takeaway") {
        return "Abhol-Restaurant";
      } else if (category == "liquor_store") {
        return "Bar";
      } else if (category == "gas_station") {
        return "Tankstelle";
      } else if (category == "bar") {
        return "Bar";
      } else if (category == "bakery") {
        return "Bäckerei";
      } else if (category == "cafe") {
        return "Café";
      } else if (category == "convenience_store") {
        return "Lebensmittelgeschäft";
      } else if (category == "stadium") {
        return "Sportsbar";
      } else if (category == "meal_delivery") {
        return "Essenslieferdienst";
      }

      return "Unbekannte Kategorie";
    },
    likeCompany(company) {
      this.liked = !this.liked;

      this.$store.dispatch("saveItem", company);
    },
    generateRandomImageUrl() {
      const randomNum = Math.floor(Math.random() * 1000); // Generiert eine Zufallszahl
      this.imageUrl = `https://loremflickr.com/150/150/restaurant?random=${randomNum}`;
    },
    checkIfLiked() {
      if (
        this.$store.state.saved.find(
          (item) => item.reference === this.company.reference
        )
      ) {
        this.liked = true;
      }
    },
  },
  computed: {
    ratingStars() {
      const filledStars = Math.floor(this.company.rating);
      const halfStar = this.company.rating - filledStars >= 0.5 ? 1 : 0;
      const emptyStars = 5 - filledStars - halfStar;

      const stars = [];
      for (let i = 0; i < filledStars; i++) {
        stars.push("filled");
      }
      if (halfStar) {
        stars.push("half");
      }
      for (let i = 0; i < emptyStars; i++) {
        stars.push("empty");
      }

      return stars;
    },
  },
};
</script>

<style lang="scss" scoped>
.company {
  transition: 0.25s ease-in-out;
  scroll-snap-align: start;
  flex: 0 0 auto;
  width: 120px;

  & + .company {
    margin-left: 15px;
  }

  &.hidden {
    display: none;
  }

  .image {
    width: 100%;
    background-color: #ddd;
    height: 120px;
    border-radius: 16px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    position: relative;
    padding: 10px 3px;
    text-align: center;

    h3 {
      font-size: 12px;
      margin: 0 0 5px;
      font-weight: 600;
    }
  }
}

.actions-btn {
  cursor: pointer;
}

.recommended-list {
  .company-list {
    .company {
      width: calc(33.333333% - 10px);
      .image {
        width: 100%;
        height: auto;
      }

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }
  }
}
</style>
