<template>
  <div class="company">
    <div class="image" @click="openRestaurant(company)">
      <img :src="imageUrl" />
    </div>

    <div class="content">
      <h3 @click="openRestaurant(company)">{{ company.name }}</h3>
      <div class="category" @click="openRestaurant(company)">
        {{ companyCategory(company.types[0]) }}
      </div>
      <div class="location" @click="openRestaurant(company)">
        {{ company.vicinity }}
      </div>

      <div class="rating" @click="openRestaurant(company)">
        <div class="rating-value">{{ ratingToFixed }}</div>

        <div class="rating-stars">
          <span v-for="star in ratingStars" :key="star" class="star">
            <i class="fas fa-star" v-if="star === 'filled'"></i>
            <i class="far fa-star" v-else></i>
          </span>
        </div>
      </div>

      <div class="actions">
        <div
          class="action-btn"
          @click="likeCompany(company)"
          :class="{ active: this.liked }"
        >
          <i class="fa-regular fa-heart" v-if="this.liked != true"></i>
          <i class="fa-solid fa-heart" v-else></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyItem",
  data() {
    return {
      liked: false,
      imageUrl: "",
    };
  },
  props: {
    company: Object,
  },
  created() {
    this.generateRandomImageUrl();
    this.checkIfLiked();
  },
  methods: {
    companyCategory(category) {
      if (category == "lodging") {
        return "Unterkunft";
      } else if (category == "restaurant") {
        return "Restaurant";
      } else if (category == "meal_takeaway") {
        return "Abhol-Restaurant";
      } else if (category == "liquor_store") {
        return "Bar";
      } else if (category == "gas_station") {
        return "Tankstelle";
      } else if (category == "bar") {
        return "Bar";
      } else if (category == "bakery") {
        return "Bäckerei";
      } else if (category == "cafe") {
        return "Café";
      } else if (category == "convenience_store") {
        return "Lebensmittelgeschäft";
      } else if (category == "stadium") {
        return "Sportsbar";
      } else if (category == "meal_delivery") {
        return "Essenslieferdienst";
      }

      return "Unbekannte Kategorie";
    },
    likeCompany(company) {
      this.liked = !this.liked;

      this.$store.dispatch("saveItem", company);
    },
    generateRandomImageUrl() {
      const randomNum = Math.floor(Math.random() * 1000); // Generiert eine Zufallszahl
      this.imageUrl = `https://loremflickr.com/150/150/restaurant?random=${randomNum}`;
    },
    checkIfLiked() {
      if (
        this.$store.state.saved.find(
          (item) => item.reference === this.company.reference
        )
      ) {
        this.liked = true;
      }
    },
    openRestaurant(restaurant) {
      this.$store.state.currentRestaurant = restaurant;

      this.$router.push({ name: "restaurant" });
    },
  },
  computed: {
    ratingStars() {
      const filledStars = Math.floor(this.company.rating);
      const halfStar = this.company.rating - filledStars >= 0.5 ? 1 : 0;
      const emptyStars = 5 - filledStars - halfStar;

      const stars = [];
      for (let i = 0; i < filledStars; i++) {
        stars.push("filled");
      }
      if (halfStar) {
        stars.push("half");
      }
      for (let i = 0; i < emptyStars; i++) {
        stars.push("empty");
      }

      return stars;
    },
    ratingToFixed() {
      if (this.company.rating) {
        return this.company.rating.toFixed(1);
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.company {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.25s ease-in-out;

  & + .company {
    margin-top: 20px;
  }

  &.hidden {
    display: none;
  }

  .image {
    width: 150px;
    background-color: #ddd;
    height: 150px;
    border-radius: 16px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
    overflow: hidden;
  }

  .content {
    position: relative;
    padding-left: 25px;
    width: calc(100% - 150px - 38px);

    h3 {
      font-size: 0.9rem;
      margin: 0 0 5px;
      font-weight: 600;
    }

    .category {
      font-weight: 200;
      font-size: 0.8rem;
      color: $md-grey;
    }

    .location {
      font-weight: 200;
      font-size: 0.8rem;
      color: #999;
      display: none;
    }

    .rating {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-top: 10px;

      .rating-value {
        font-weight: 500;
        margin-right: 8px;
        font-size: 0.9rem;
      }

      .star {
        color: $primary;
        font-size: 0.9rem;
      }
    }

    .actions {
      .action-btn {
        position: absolute;
        bottom: 0;
        right: -38px;
        font-size: 1rem;
        width: 32px;
        height: 32px;
        background-color: #ddd;
        border-radius: 100px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.25s ease-in-out;

        i {
          transition: 0.25s ease-in-out;
        }

        &.active {
          background-color: $primary;
          color: #fff;
        }
      }
    }
  }
}

.actions-btn {
  cursor: pointer;
}
</style>
