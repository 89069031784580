<template>
  <div>
    <h3>Standortabfrage</h3>
    <div v-if="isUserNearby">
      <div class="wow">Du bist in der Nähe des Ziels!</div>

      <div class="actions-nearby">
        <div
          v-for="action in showNearActions"
          :key="action.name"
          class="restaurant-action"
          @click="openAction(action)"
        >
          <h4>{{ action.name }}</h4>
          <div class="action-details">
            <span class="action-title">{{ action.promotion.title }}</span> -
            <span class="action-subtitle">{{ action.promotion.subtitle }}</span>
            <div class="action-validity">{{ action.promotion.validity }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="userLocation">
      Dein aktueller Standort: Latitude: {{ userLocation.latitude }}, Longitude:
      {{ userLocation.longitude }}
    </div>
    <div v-else>Standort wird geladen oder Zugriff nicht erlaubt.</div>
  </div>
</template>

<script>
import companiesData from "@/assets/companies.json";

export default {
  name: "ActionsView",
  data() {
    return {
      userLocation: null,
      targetLocation: {
        latitude: 49.4557771,
        longitude: 9.4728644,
      },
      allowedDistance: 50, // Distanz in Metern
      restaurantActions: [
        {
          title: "2für1-Aktion",
          subtitle: "Kaufe 2 Pizzen, zahle nur eine",
          validity: "Gültig bis Sonntag, 4. Februar 2024",
        },
        {
          title: "Kostenloses Dessert",
          subtitle: "Kostenloses Dessert bei jedem Hauptgericht",
          validity: "Gültig bis Sonntag, 4. Februar 2024",
        },
        {
          title: "Happy Hour",
          subtitle: "Alle Getränke zum halben Preis",
          validity: "Gültig bis Sonntag, 4. Februar 2024",
        },
        {
          title: "Kinder essen kostenlos",
          subtitle: "Kinder essen kostenlos",
          validity: "Gültig bis Sonntag, 4. Februar 2024",
        },
        {
          title: "10% Rabatt",
          subtitle: "10% Rabatt auf die Gesamtrechnung",
          validity: "Gültig bis Sonntag, 4. Februar 2024",
        },
        {
          title: "Kostenlose Vorspeise",
          subtitle: "Kostenlose Vorspeise für Tischreservierungen",
          validity: "Gültig bis Sonntag, 4. Februar 2024",
        },
        {
          title: "Bieraktion",
          subtitle: "Jedes dritte Bier gratis",
          validity: "Gültig bis Sonntag, 4. Februar 2024",
        },
        {
          title: "Live-Musik-Abend",
          subtitle: "Freier Eintritt zum Live-Musik-Abend",
          validity: "Gültig bis Sonntag, 4. Februar 2024",
        },
        {
          title: "Kostenloser Kaffee",
          subtitle: "Kostenloser Kaffee zum Frühstück",
          validity: "Gültig bis Sonntag, 4. Februar 2024",
        },
        {
          title: "Menüaktion",
          subtitle: "Kaufe ein Menü, das zweite Menü gibt es zum halben Preis",
          validity: "Gültig bis Sonntag, 4. Februar 2024",
        },
      ],
    };
  },
  computed: {
    isUserNearby() {
      if (!this.userLocation) return false;
      return (
        this.calculateDistance(this.userLocation, this.targetLocation) <
        this.allowedDistance / 1000
      );
    },
    showNearActions() {
      return companiesData
        .filter((restaurant) => {
          const restaurantLocation = {
            latitude: restaurant.geometry.location.lat,
            longitude: restaurant.geometry.location.lng,
          };
          const distance = this.calculateDistance(
            this.userLocation,
            restaurantLocation
          );
          return distance <= this.allowedDistance / 1000;
        })
        .map((restaurant) => {
          const randomIndex = Math.floor(
            Math.random() * this.restaurantActions.length
          );
          return {
            name: restaurant.name,
            promotion: this.restaurantActions[randomIndex],
          };
        });
    },
  },
  created() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        title: "Neue Aktion in der Nähe",
        body: "20% auf alle Biere!",
        imageUrl:
          "https://nearbite.fabiannaglo.de/backend/notification-image.jpg",
      }),
    };

    fetch("https://nearbite.fabiannaglo.de/backend/backend.php", requestOptions)
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) =>
        console.error("Fehler beim Senden der Benachrichtigung:", error)
      );
  },
  mounted() {
    this.watchUserLocation();
  },
  methods: {
    watchUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.watchPosition(
          (position) => {
            this.userLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            this.checkProximity();
          },
          (error) => {
            console.error("Error watching position:", error);
          },
          { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    checkProximity() {
      const distance = this.calculateDistance(
        this.userLocation,
        this.targetLocation
      );
      if (distance < 0.05) {
        console.log("PUSH!");
      }
    },
    getUserLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.userLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
          },
          () => {
            alert("Zugriff auf den Standort wurde verweigert.");
            this.userLocation = {}; // Setze ein leeres Objekt, um weitere Fehler zu vermeiden
          }
        );
      } else {
        alert("Geolocation wird von diesem Browser nicht unterstützt.");
        this.userLocation = {}; // Setze ein leeres Objekt, um weitere Fehler zu vermeiden
      }
    },
    calculateDistance(loc1, loc2) {
      const R = 6371; // Radius der Erde in Kilometern
      const dLat = this.deg2rad(loc2.latitude - loc1.latitude);
      const dLon = this.deg2rad(loc2.longitude - loc1.longitude);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(loc1.latitude)) *
          Math.cos(this.deg2rad(loc2.latitude)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Entfernung in Kilometern
      return distance;
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    openAction(action) {
      this.$store.state.currentAction = action;

      this.$router.push({ name: "aktion" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("@/assets/styles/_variables.scss");

.wow {
  margin: 0 0 30px;
  font-weight: 600;
}
.restaurant-action {
  cursor: pointer;
  & + .restaurant-action {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid $md-grey;
  }

  h4 {
    margin: 0 0;
    font-size: 1rem;
    font-weight: 400;
    font-size: 0.8rem;
    color: $md_grey;
  }

  .action-details {
    cursor: pointer;
    .action-title {
      color: $primary;
      font-weight: 600;
    }

    .action-subtitle {
    }

    .action-validity {
      font-size: 0.8rem;
      color: $md_grey;
      font-weight: 600;
    }
  }
}
</style>
