import { createStore } from "vuex";

export default createStore({
  state: {
    bitePoints: 382,
    pages: [
      {
        id: 1,
        name: "Home",
        icon: "fa-home",
        route: "/home",
        isActive: false,
      },
      {
        id: 2,
        name: "Suche",
        icon: "fa-search",
        route: "/suche",
        isActive: false,
      },
      {
        id: 3,
        name: "Aktionen",
        icon: "fa-bolt",
        route: "/aktionen",
        isActive: false,
      },
      {
        id: 4,
        name: "Mitteilungen",
        icon: "fa-envelope",
        route: "/mitteilungen",
        isActive: false,
      },
      {
        id: 5,
        name: "Gespeichert",
        icon: "fa-bookmark",
        route: "/gespeichert",
        isActive: false,
      },
    ],
    saved: JSON.parse(localStorage.getItem("saved")) || [],
  },
  getters: {},
  mutations: {
    setActive(state, itemId) {
      state.activeItem = state.pages.find((item) => item.id === itemId);
      state.pages.forEach((item) => (item.isActive = item.id === itemId));
    },
    saveItemCompany(state, company) {
      const index = state.saved.findIndex(
        (item) => item.reference === company.reference
      );
      if (index !== -1) {
        state.saved.splice(index, 1);
      } else {
        state.saved.push(company);
      }

      localStorage.setItem("saved", JSON.stringify(state.saved));
    },
  },
  actions: {
    setActiveItem({ commit }, itemId) {
      commit("setActive", itemId);
    },
    saveItem({ commit }, company) {
      commit("saveItemCompany", company);
    },
  },
  modules: {},
});
