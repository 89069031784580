import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "onboarding",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/OnboardingView.vue"),
    meta: {
      title: "Willkommen",
    },
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    meta: {
      title: "Startseite",
    },
  },
  {
    path: "/suche",
    name: "suche",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SearchView.vue"),
    meta: {
      title: "Suche",
    },
  },
  {
    path: "/aktionen",
    name: "aktionen",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ActionsView.vue"),
    meta: {
      title: "Aktionen",
    },
  },
  {
    path: "/mitteilungen",
    name: "mitteilungen",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotificationsView.vue"),
    meta: {
      title: "Mitteilungen",
    },
  },
  {
    path: "/gespeichert",
    name: "gespeichert",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SavedView.vue"),
    meta: {
      title: "Gespeicherte Restaurants",
    },
  },
  {
    path: "/bites",
    name: "bites",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BitesView.vue"),
    meta: {
      title: "Bites",
    },
  },
  {
    path: "/aktion",
    name: "aktion",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ActionDetailView.vue"),
    meta: {
      title: "Aktion",
    },
  },
  {
    path: "/scanner",
    name: "scanner",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/QrCodeScanner.vue"),
    meta: {
      title: "Scanner",
    },
  },
  {
    path: "/restaurants",
    name: "restaurants",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RestaurantListing.vue"),
    meta: {
      title: "Restaurants",
    },
  },
  {
    path: "/restaurant",
    name: "restaurant",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RestaurantDetail.vue"),
    meta: {
      title: "Restaurant",
    },
  },
  {
    path: "/einstellungen",
    name: "einstellungen",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserSettings.vue"),
    meta: {
      title: "Einstellungen",
    },
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "nearbite";

  next();
});

export default router;
