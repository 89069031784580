<template>
  <div class="bottom-bar">
    <router-link
      v-for="item in menuItems"
      :key="item.id"
      class="menu-item"
      :class="{ active: item.isActive }"
      @click="setActiveItem(item.id, item.name)"
      :to="item.route"
    >
      <i :class="['fa', item.icon]"></i>
      <span>{{ item.name }}</span>
    </router-link>
  </div>
</template>

<script>
import Logo from "@/assets/logo.svg";

export default {
  name: "NavBar",
  data() {
    return {
      logo: Logo,
    };
  },
  computed: {
    menuItems() {
      return this.$store.state.pages;
    },
  },
  methods: {
    setActiveItem(itemId, name) {
      this.$store.dispatch("setActiveItem", itemId);

      this.$emit('navigate', name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("@/assets/styles/_variables.scss");

.bottom-bar {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 80px;
  border-top: 1px solid #ddd;
  padding: 0 30px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a.menu-item {
    color: $md_grey;
    display: block;
    text-decoration: none;
    text-align:center;

    &.router-link-active{
        color: $primary;
    }

    i {
      text-align: center;
      display: block;
      margin: 0 0 3px;
      font-size: 1rem;
    }

    span {
      font-weight: 500;
      display: block;
      font-size: 0.8rem;
    }
  }
}
</style>
