<template>
  <div class="notification-list">
    <NotificationItem
      v-for="notification in reversedNotificationArray"
      :key="notification.title"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationItem from "@/components/notifications/NotificationItem.vue";

export default {
  name: "NotificationsView",
  data() {
    return {
      notificationArray: [
        {
          id: 1,
          title: "Neuer Rabatt in deiner Nähe",
          subtitle:
            "Exklusive 20% Rabatt auf deine nächste Bestellung zum Mitnehmen!",
          datetime: "So., 09:39",
        },
        {
          id: 2,
          title: "2für1-Aktion in deiner Nähe",
          subtitle: "Bestelle zwei Pizzen zum Preis von einer!",
          datetime: "So., 10:02",
        },
        {
          id: 3,
          title: "Happy Hour Special",
          subtitle:
            "Genieße 50% Rabatt auf alle Cocktails von 17:00 bis 19:00 Uhr!",
          datetime: "So., 12:15",
        },
        {
          id: 4,
          title: "Gratis Kaffee am Morgen",
          subtitle: "Erhalte einen kostenlosen Kaffee zu deinem Frühstück!",
          datetime: "Mo., 06:30",
        },
        {
          id: 5,
          title: "Backwaren Rabatt",
          subtitle: "25% Rabatt auf alle Brote und Kuchen nach 18:00 Uhr!",
          datetime: "Mo., 10:05",
        },
        {
          id: 6,
          title: "Sushi Sonderangebot",
          subtitle: "Kaufe ein Sushi-Set und erhalte das zweite gratis dazu!",
          datetime: "Mo., 14:20",
        },
        {
          id: 7,
          title: "Kostenlose Lieferung",
          subtitle:
            "Nutze diesen Code für eine kostenlose Lieferung deiner nächsten Bestellung!",
          datetime: "Mo., 16:45",
        },
        {
          id: 8,
          title: "Brunch Spezial",
          subtitle: "20% Rabatt auf alle Brunch-Menüs in dieser Woche!",
          datetime: "Mo., 22:00",
        },
        {
          id: 9,
          title: "Italienische Nacht",
          subtitle:
            "Erhalte eine kostenlose Flasche Wein zu jeder Pasta-Bestellung!",
          datetime: "Mo., 22:30",
        },
        {
          id: 10,
          title: "Burger & Bier Combo",
          subtitle:
            "Sichere dir eine Burger-Bier-Kombi zum Sonderpreis von nur 10€!",
          datetime: "09:50",
        },
        {
          id: 11,
          title: "Veganes Menü Angebot",
          subtitle: "10% Rabatt auf alle veganen Gerichte diese Woche!",
          datetime: "11:11",
        },
        {
          id: 12,
          title: "Familienpizza Deal",
          subtitle: "Große Familienpizza mit 4 Getränken für nur 20€!",
          datetime: "14:25",
        },
        {
          id: 13,
          title: "Dessert Aktion",
          subtitle:
            "Kaufe ein Hauptgericht und erhalte ein Dessert gratis dazu!",
          datetime: "15:36",
        },
        {
          id: 14,
          title: "Mittagsmenü Rabatt",
          subtitle:
            "Spare 15% auf ausgewählte Mittagsmenüs zwischen 12:00 und 14:00 Uhr!",
          datetime: "15:56",
        },
        {
          id: 15,
          title: "Late-Night Snack",
          subtitle: "Erhalte 30% Rabatt auf alle Bestellungen nach 22:00 Uhr!",
          datetime: "16:05",
        },
      ],
    };
  },
  components: {
    NotificationItem,
  },
  computed: {
    reversedNotificationArray() {
      return [...this.notificationArray].reverse();
    },
  },
};
</script>

<style lang="scss" scoped></style>
