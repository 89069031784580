import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyATW1Ybkfqz2Bt3-0nayrfz2pww_o6DGkQ",
  authDomain: "nearbite-534a8.firebaseapp.com",
  projectId: "nearbite-534a8",
  storageBucket: "nearbite-534a8.appspot.com",
  messagingSenderId: "46296819811",
  appId: "1:46296819811:web:b250380cf6449eca75f056",
  measurementId: "G-MSPVWV31GG",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
