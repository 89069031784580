<template>
  <TopBar v-if="showNavBar" class="fade-in" v-cloak />

  <main class="fade-in" v-cloak :class="{ 'no-padding': !mainPadding }">
    <router-view v-slot="{ Component }">
      <transition name="slide" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>

  <BottomBar class="fade-in" v-cloak v-if="showBottomBar" />
</template>

<script>
import TopBar from "@/components/main/TopBar.vue";
import BottomBar from "@/components/main/BottomBar.vue";

import HomeView from "@/views/HomeView.vue";
import SucheView from "@/views/SearchView.vue";
import AktionenView from "@/views/ActionsView.vue";
import BenachrichtigungenView from "@/views/NotificationsView.vue";
import GespeichertView from "@/views/SavedView.vue";

import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase";

export default {
  name: "App",
  components: {
    TopBar,
    BottomBar,
    HomeView,
    SucheView,
    AktionenView,
    BenachrichtigungenView,
    GespeichertView,
  },
  data() {
    return {
      currentView: "HomeView",
      showNavBar: true,
      showBottomBar: true,
      mainPadding: true,
    };
  },
  methods: {
    setCurrentView(componentName) {
      this.currentView = componentName + "View";
    },
    registerServiceWorker() {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then((registration) => {
            console.log(
              "Service Worker für Firebase Messaging registriert:",
              registration
            );
          })
          .catch((err) => {
            console.log(
              "Fehler bei der Registrierung des Service Worker:",
              err
            );
          });
      }
    },
  },
  watch: {
    $route(to) {
      if (to.path === "/") {
        this.showNavBar = false;
        this.showBottomBar = false;
        this.mainPadding = true;
      } else if (to.path === "/home") {
        this.showNavBar = true;
        this.showBottomBar = true;
        this.mainPadding = false;
      } else {
        this.showNavBar = true;
        this.showBottomBar = true;
        this.mainPadding = true;
      }
    },
  },
  created() {
    if (this.$route.path === "/onboarding") {
      this.showNavBar = false;
      this.showBottomBar = false;
      this.mainPadding = true;
    } else if (this.$route.path === "/") {
      this.showNavBar = true;
      this.showBottomBar = true;
      this.mainPadding = false;
    } else {
      this.showNavBar = true;
      this.showBottomBar = true;
      this.mainPadding = true;
    }
  },
  mounted() {
    this.registerServiceWorker();
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");

          messaging.getToken().then((token) => {
            console.log(token);
          });
        } else {
          console.log("Unable to get permission to notify.");
        }
      })
      .catch((err) => {
        console.log(
          "An error occurred while requesting notification permission",
          err
        );
      });

    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      // Behandle die empfangene Nachricht
    });
  },
};
</script>

<style lang="scss">
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import url("@/assets/styles/_fonts.css");
@import url("@/assets/styles/_variables.scss");

* {
  box-sizing: border-box;
}

html,
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  font-family: "Urbanist", sans-serif;
  font-size: 16px;
  line-height: 1.3;
  margin: 0;
  padding-top: 80px;
  padding-bottom: 80px;
}

.top-bar {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #ddd;
  padding: 0 30px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#app {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

main {
  padding: 30px;
  height: 100%;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.slide-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.fade-in {
  animation: fadeInAnimation 0.5s ease-in-out;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

[v-cloak] {
  display: none;
}
</style>
