<template>
  <div>
    <h3>Meine gespeicherten Restaurants</h3>

    <div class="company-list">
      <CompanyList :companies="savedCompanies" />
    </div>
  </div>
</template>

<script>
import CompanyList from "@/components/listing/CompanyList.vue";

export default {
  name: "savedView",
  components: {
    CompanyList,
  },
  computed: {
    savedCompanies() {
      return this.$store.state.saved.filter((company) =>
        company.vicinity.includes("Mosbach")
      );
    },
  },
};
</script>

<style lang="scss" scoped>

h3{
  margin:0 0 45px;
  font-size: 1.4rem;
  font-weight:600;
}

</style>
