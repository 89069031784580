<template>
  <div class="home">
    <div class="company-list">
      <div class="headline">
        <h3>In deiner Nähe</h3>
        <router-link to="/restaurants">mehr</router-link>
      </div>
      <HomeRestaurants :companies="filteredCompanies" :max-items="10" />
    </div>

    <div class="deals-list">
      <div class="headline">
        <h3>Deine Deals der Woche</h3>
        <router-link to="/aktionen">mehr</router-link>
      </div>

      <div class="list">
        <div
          class="deal"
          v-for="deal in deals"
          :key="deal.id"
          @click="openAction(deal)"
        >
          <div class="big">{{ deal.big }}</div>
          <div class="medium">{{ deal.medium }}</div>
          <div class="small">{{ deal.small }}</div>
        </div>
      </div>
    </div>

    <div class="company-list recommended-list">
      <div class="headline">
        <h3>Wir empfehlen</h3>
        <router-link to="/restaurants">mehr</router-link>
      </div>
      <HomeRestaurants :companies="filteredCompanies" :max-items="6" />
    </div>
  </div>
</template>

<script>
import companiesData from "@/assets/companies.json";
import HomeRestaurants from "@/components/listing/HomeRestaurants.vue";

export default {
  name: "HomeView",
  data() {
    return {
      companies: companiesData,
      deals: [
        {
          id: 1,
          big: "20%",
          medium: "auf alle Biere",
          small: "Brauhaus Mosbach",
        },
        {
          id: 2,
          big: "5€",
          medium: "Shawarma",
          small: "West Beirut Restaurant",
        },
        {
          id: 3,
          big: "3€",
          medium: "Döner",
          small: "RIHA",
        },
        {
          id: 4,
          big: "50%",
          medium: "auf alle Pizzen",
          small: "Fellini Holzofenpizza & Salate",
        },
        {
          id: 5,
          big: "5€",
          medium: "XL Tscheburek",
          small: "Imbiss Wolga",
        },
        {
          id: 6,
          big: "1€",
          medium: "pro Shot",
          small: "LUDWIG - Restaurant - Bar",
        },
        {
          id: 7,
          big: "3€",
          medium: "Nudelbox nach Wahl",
          small: "Tan Do Asia Imbiss",
        },
        {
          id: 8,
          big: "3€",
          medium: "Nudelbox nach Wahl",
          small: "Tan Do Asia Imbiss",
        },
      ],
    };
  },
  components: {
    HomeRestaurants,
  },
  computed: {
    filteredCompanies() {
      return this.companies.filter((company) =>
        company.vicinity.includes("Mosbach")
      );
    },
  },
  methods: {
    openAction(action) {
      action.promotion = {};
      action.promotion.title = action.big;
      action.promotion.subtitle = action.medium;
      action.name = action.small;

      this.$store.state.currentAction = action;

      this.$router.push({ name: "aktion" });
    },
  },
};
</script>

<style lang="scss" scoped>
.company-list {
  .company-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .company {
      display: block;
      margin: 0 !important;
    }
  }
}

.headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px;

  h3 {
    margin: 0;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    color: $primary;
  }
}

.deals-list {
  margin: 0 0 45px;
  .list {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    margin: 0 -30px 0;
    padding-left: 30px;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;

    .deal {
      min-width: 255px;
      background-color: $primary;
      color: #fff;
      padding: 25px 15px;
      border-radius: 16px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
      text-align: center;
      scroll-snap-align: center;

      & + .deal {
        margin-left: 15px;
      }

      .big {
        font-size: 42px;
        font-weight: 600;
        line-height: 1;
      }

      .medium {
        font-size: 18px;
        letter-spacing: -0.1px;
        font-family: "Bebas Neue", sans-serif;
        margin: 2px 0 8px;
      }

      .small {
        font-size: 14px;
        color: #000;
        font-weight: 500;
      }
    }
  }
}

.recommended-list {
  margin-bottom: 0;
  background-color: #ffc1a6;
  margin: 0 -30px;
  padding: 30px 30px 15px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  .company-list {
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }
}
</style>
