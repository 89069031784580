<template>
  <div class="company-list">
    <CompanyItem
      v-for="company in filteredCompanies"
      :key="company.place_id"
      :company="company"
    />
  </div>
</template>

<script>
import CompanyItem from "@/components/listing/CompanyItem.vue";

export default {
  name: "CompanyList",
  components: {
    CompanyItem,
  },
  props: {
    companies: Array
  },
  computed: {
    filteredCompanies() {
      return this.companies.filter((company) =>
        company.vicinity.includes("Mosbach")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
  .company-list{
    padding-bottom:30px;
  }
</style>
