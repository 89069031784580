<template>
  <div class="search">
    <div class="search-results">
      <CompanyList :companies="filteredCompanies" />

      <div class="no-results" v-if="noResults">
        Keine Ergebnisse für {{ suchbegriff }} gefunden.
      </div>
    </div>

    <div class="search-input">
      <div class="input-form">
        <input
          name="search"
          id="search"
          placeholder="Suchbegriff eingeben ..."
          type="text"
          @input="checkResults"
        />
        <i class="fa-solid fa-magnifying-glass"></i>
      </div>
    </div>
  </div>
</template>

<script>
import companiesData from "@/assets/companies.json";
import CompanyList from "@/components/listing/CompanyList.vue";

export default {
  name: "SearchView",
  components: {
    CompanyList,
  },
  data() {
    return {
      suchbegriff: "",
      noResults: false,
      companies: companiesData,
    };
  },
  methods: {
    checkResults(evt) {
      const inputValue = evt.target.value;
      this.suchbegriff = inputValue;

      const results = document.querySelectorAll(".company h3");

      results.forEach((item) => {
        if (!item.textContent.includes(inputValue)) {
          item.parentElement.parentElement.classList.add("hidden");
        } else {
          item.parentElement.parentElement.classList.remove("hidden");
        }
      });

      if (results[0].parentElement.parentElement.classList.contains("hidden")) {
        document.querySelector(".search-results").classList.add("mtop");
      } else {
        document.querySelector(".search-results").classList.remove("mtop");
      }

      if (document.querySelectorAll(".company:not(.hidden)").length <= 0) {
        this.noResults = true;
      } else {
        this.noResults = false;
      }
    },
  },
  computed: {
    filteredCompanies() {
      return this.companies.filter((company) =>
        company.vicinity.includes("Mosbach")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  height: 100%;
  position: relative;
}

.search-results {
  min-height: calc(100vh - 283px);

  &.mtop {
    margin-top: -20px;
  }

  .no-results {
    padding: 0 0 30px;
    text-align: center;
    font-weight: 500;
    height: calc(100vh - 293px);
  }
}

.search-input {
  position: sticky;
  bottom: 0;
  left: 0;
  width: calc(100% + 60px);
  z-index: 1;
  background: $lg-grey;
  border-top: 3px solid $primary;
  margin: 0 -30px;
  padding: 20px 30px;

  .input-form {
    position: relative;
    width: 100%;
    height: 100%;

    input {
      width: 100%;
      line-height: 50px;
      border-radius: 30px;
      border: none;
      outline: none !important;
      padding: 0 40px 0 20px;
    }

    i {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 50px;
      height: 100%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
      pointer-events: none;
    }
  }
}
</style>
