<template>
  <div class="company-list">
    <CompanyItem
      v-for="company in limitedFilteredCompanies"
      :key="company.place_id"
      :company="company"
      @click="openRestaurant(company)"
    />
  </div>
</template>

<script>
import CompanyItem from "@/components/listing/HomeRestaurantsItem.vue";

export default {
  name: "HomeRestaurants",
  components: {
    CompanyItem,
  },
  props: {
    companies: Array,
    maxItems: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    shuffleArray(array) {
      let currentIndex = array.length,
        randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
    openRestaurant(restaurant) {
      this.$store.state.currentRestaurant = restaurant;

      this.$router.push({ name: "restaurant" });
    },
  },
  computed: {
    filteredCompanies() {
      return this.companies.filter((company) =>
        company.vicinity.includes("Mosbach")
      );
    },
    limitedFilteredCompanies() {
      let shuffledCompanies = this.shuffleArray([...this.filteredCompanies]);

      return shuffledCompanies.slice(0, this.maxItems);
    },
  },
};
</script>

<style lang="scss" scoped>
.company-list {
  display: flex;
  padding-bottom: 30px;
  padding-left: 30px;
  overflow-x: scroll;
  touch-action: pan-x;
  margin: 0 -30px 0 0;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
}
</style>
